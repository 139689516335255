"use client";

import React, { useCallback, useEffect, useState } from "react";
import Button from "@/components/Button";
import {
  LeftArrowIcon,
  RightArrowIcon,
  UpRightArrowIcon,
} from "@/components/Icon";
import Typography from "@/components/Typography";
import NextLink from "next/link";

import styles from "./styles.module.css";
import Image from "next/image";
import IconButton from "@/components/IconButton";

import useEmblaCarousel from "embla-carousel-react";
import { EmblaCarouselType } from "embla-carousel";
import { useThrottledCallback } from "use-debounce";
import Link from "@/components/Link";

const digitalCases = [
  {
    img: "/graphic.svg",
    title: "Продвижение",
    href: "/services/promotion",
    description: (
      <>
        <ul className={styles.card__list}>
          <li>
            <Typography variant="p3" color="description">
              SEO
            </Typography>
          </li>
          <li>
            <Typography variant="p3" color="description">
              Контекстная реклама
            </Typography>
          </li>
        </ul>
      </>
    ),
  },
  {
    img: "/logotype.svg",
    title: "Логотип",
    href: "",
    description: (
      <>
        <Typography
          variant="p3"
          color="description"
          className={styles.card__description}
        >
          Разработка уникального логотипа, отражающего индивидуальность вашего
          бренда.
        </Typography>
      </>
    ),
  },
  {
    img: "/style.svg",
    title: "Фирменный стиль",
    href: "",
    description: (
      <>
        <Typography
          variant="p3"
          color="description"
          className={styles.card__description}
        >
          Разработка логотипа, визуального стиля бренда и графических элементов,
          позиционирование бренда. Разработка руководства по фирменному стилю.
        </Typography>
      </>
    ),
  },
  {
    img: "/marketing-strategy.svg",
    title: "Маркетинговая стратегия",
    href: "",
    description: (
      <>
        <Typography
          variant="p3"
          color="description"
          className={styles.card__description}
        >
          Разработка плана, направленного на достижение бизнес-целей через
          анализ рынка.
        </Typography>
      </>
    ),
  },
];

const DigitalMarketing: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: true,
    active: true,
    align: "start",
    // breakpoints: { "(min-width: 1440px)": { active: false } },
  });

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [canScroll, setCanScroll] = useState(false);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
    setCanScroll(true);
  }, []);
  const onSelectThrottled = useThrottledCallback(onSelect, 100);
  const onScroll = useCallback(() => {
    setCanScroll(false);
  }, []);
  const onScrollThrottled = useThrottledCallback(onScroll, 100);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect, onSelectThrottled, onScroll]);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi || !canScroll) return;
    emblaApi.scrollPrev();
  }, [emblaApi, canScroll]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi || !canScroll) return;
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi, canScroll]);

  return (
    <section className={`${styles.container} ${className ? className : ""}`}>
      <Typography variant="h5" color="accent" className={styles.header}>
        Услуги
      </Typography>

      <div className={styles.cta}>
        <Typography variant="h2" className={styles.headline}>
          Digital-маркетинг
        </Typography>
        <Typography variant="p1" color="subtitle" className={styles.subtitle}>
          Стратегия продвижения с использованием цифровых технологий, помогает
          улучшить узнаваемость бренда и повысить лояльность покупателей.
        </Typography>
      </div>

      <section className={styles.cases} ref={emblaRef}>
        <div className={styles.embla__container}>
          {digitalCases.map(({ description, img, title, href }, idx) => (
            <div className={styles.card} key={idx}>
              <Image
                className={styles.card__image}
                src={img}
                alt={title}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
              <div className={styles.card__content}>
                {href ? (
                  <Link asChild>
                    <NextLink
                      href={href}
                      className={styles.card__headline__link}
                    >
                      <Typography
                        variant="h3"
                        className={styles.card__headline}
                      >
                        {title}
                      </Typography>
                    </NextLink>
                  </Link>
                ) : (
                  <Typography variant="h3" className={styles.card__headline}>
                    {title}
                  </Typography>
                )}
                {description}
                {href && (
                  <Link className={styles["card__more-link"]} asChild>
                    <NextLink href={href}>
                      Узнать подробнее{" "}
                      <UpRightArrowIcon className={styles["card__more-icon"]} />
                    </NextLink>
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.navigation}>
          <IconButton
            Icon={LeftArrowIcon}
            className={styles["navigation-button"]}
            disabled={prevBtnDisabled}
            onClick={onPrevButtonClick}
          />
          <IconButton
            Icon={RightArrowIcon}
            className={styles["navigation-button"]}
            disabled={nextBtnDisabled}
            onClick={onNextButtonClick}
          />
        </div>
      </section>
    </section>
  );
};
DigitalMarketing.displayName = "DigitalMarketing";

export default DigitalMarketing;
