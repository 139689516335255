"use client";

import React, { useEffect, useMemo, useState } from "react";
import Button from "@/components/Button";
import { UpRightArrowIcon } from "@/components/Icon";
import Typography from "@/components/Typography";

import styles from "./styles.module.css";
import Image from "next/image";
import NextLink from "next/link";
import IconButton from "@/components/IconButton";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { useMediaQuery } from "usehooks-ts";
import Link from "@/components/Link";

import caseImg1 from "../../../../public/images/portfolio/wheat-field.png";
import caseImg2 from "../../../../public/factory.png";
import caseImg3 from "../../../../public/cookie.png";
import caseImg4 from "../../../../public/gpt.png";
import caseImg5 from "../../../../public/vehicle.png";
import caseImg6 from "../../../../public/building.png";

const casesCards = [
  {
    img: caseImg1,
    href: "/cases/lichnyj-kabinetdlja-agregacii-zakazov-v-selskohozjajstvennoj-otrasli",
    badge: "Промышленность",
    title: "Разработка личного кабинета",
    description: "Ведущий дистрибьютор семян и агросервиса в Восточной Сибири",
    points: [
      {
        bigText: "90%",
        smallText: "клиентов используют ЛК",
      },
      {
        bigText: "x3",
        smallText: "скорость обработки заявок",
      },
    ],
  },
  {
    img: caseImg2,
    href: "/cases/bajkalskiy-gazobeton-smm-audit",
    badge: "Строительство",
    title: "Дизайн-аудит маркетинговых каналов",
    description: "Ведущий дистрибьютор семян и агросервиса в Восточной Сибири",
    points: [
      {
        bigText: "3",
        smallText: "релиза MVP ",
      },
      {
        bigText: "x2",
        smallText: "целевой трафик ",
      },
    ],
  },
  {
    img: caseImg3,
    href: "/cases/shkola-vkusa-prjanosti-sladosti",
    badge: "Общепит",
    title: "Разработка фирменного стиля",
    description: "Кулинарная школа в Югорске",
    points: [
      {
        bigText: '',
        smallText: "Точный нейминг",
      },
      {
        bigText: '',
        smallText: "Запоминающийся логотип",
      },
    ],
  },
  {
    img: caseImg4,
    href: "/cases/sozdanie-ai-reshenija-s-ispolzovaniem-chatgpt-dlja-kiwi-immigration",
    badge: "Юридические услуги",
    title: "Внедрение искусственного интеллекта (AI)",
    description:
      "Сервис для создания сопроводительного письма в иммиграционную службу",
    points: [
      {
        bigText: "x5",
        smallText: "скорость создания сопроводительного письма",
      },
      {
        bigText: "50%",
        smallText: "оптимизирована работа сотрудников",
      },
    ],
  },
  {
    img: caseImg5,
    href: "/cases/cifrovaja-transformacija-zavoda-gusenichnoj-tehniki",
    badge: "Промышленность",
    title: "Цифровизация завода",
    description:
      "Крпнейший производитель вездеходов в Восточной Сибири и на Дальнем Востоке",
    points: [
      {
        bigText: "80%",
        smallText: "уменьшили потери инстурмента",
      },
      {
        bigText: "1",
        smallText: "месяц на внедрение",
      },
    ],
  },
  {
    img: caseImg6,
    href: "/cases/tsk-brigada",
    badge: "Строительство",
    title: "Рекламная кампания",
    description:
      "Эксперт малоэтажного строительства и лидера на рынке Иркутской области",
    points: [
      {
        bigText: "100%",
        smallText: "прозрачность каналов",
      },
      {
        bigText: "x2",
        smallText: "количество заявок",
      },
    ],
  },
];

const Cases: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const [width, _] = useWindowDimensions();
  const isMobileQuery = useMediaQuery("(max-width: 767px)");
  const [displayCases, setDisplayCases] = useState(2);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);

  useEffect(() => {
    if (isMobile) {
      setDisplayCases(2);
    } else {
      setDisplayCases(casesCards.length);
    }
  }, [isMobile]);

  return (
    <section className={`${styles.container} ${className ? className : ""}`}>
      <Typography variant="h5" color="accent">
        Кейсы
      </Typography>

      <div className={styles.cta}>
        <Typography variant="h2" className={styles.headline}>
          Более 300 успешно завершённых проектов
        </Typography>
        <Button
          variant="outline"
          RightElement={UpRightArrowIcon}
          className={styles.cta__button}
          asChild
        >
          <NextLink href="/cases">Смотреть все кейсы</NextLink>
        </Button>
      </div>

      <section className={styles.cases}>
        {casesCards
          .slice(0, displayCases)
          .map(({ img, href, badge, description, title, points }, idx) => (
            <div className={styles.case} key={idx}>
              <div className={styles.card}>
                <Image
                  className={styles["card-image"]}
                  src={img}
                  alt=""
                  fill
                  placeholder="blur"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                />
                <div className={styles.badge}>{badge}</div>
                <div className={styles["card-hover"]}>
                  <div
                    className={`${styles["card-hover__content"]} ${
                      (points?.length || 0) == 0
                        ? styles["card-hover__content__single"]
                        : ""
                    }`}
                  >
                    {points && points.length > 1 ? (
                      <>
                        <span className={styles["card-hover__text-big"]}>
                          {points[0].bigText}
                        </span>{" "}
                        <span className={styles["card-hover__text-small"]}>
                          {points[0].smallText}
                        </span>
                        <span className={styles["card-hover__text-big"]}>
                          {points[1].bigText}
                        </span>{" "}
                        <span className={styles["card-hover__text-small"]}>
                          {points[1].smallText}
                        </span>
                      </>
                    ) : (
                      <span
                        className={`${styles["card-hover__text-small"]} ${styles["card-hover__text-single"]}`}
                      >
                        Ознакомиться с кейсом
                      </span>
                    )}
                    {/* <span className={styles["card-hover__text-big"]}>x3</span>{" "}
                      <span className={styles["card-hover__text-small"]}>
                        Рост конверсии
                      </span>
                      <span className={styles["card-hover__text-big"]}>60</span>{" "}
                      <span className={styles["card-hover__text-small"]}>
                        Часов работы
                      </span> */}
                    <IconButton
                      Icon={UpRightArrowIcon}
                      variant="circle"
                      className={styles["card-hover__button"]}
                      asChild
                    >
                      <NextLink href={href} />
                    </IconButton>
                  </div>
                </div>
              </div>
              <Link asChild>
                <NextLink href={href}>
                  <Typography variant="h5" className={styles.case__headline}>
                    {title}
                  </Typography>
                </NextLink>
              </Link>
              <Typography
                variant="p2"
                color="description"
                className={styles.case__description}
              >
                {description}
              </Typography>
            </div>
          ))}
      </section>
      {isMobile && displayCases < casesCards.length && (
        <Button
          variant="outline"
          className={styles["show-more-button"]}
          onClick={() => setDisplayCases((prev) => prev + 2)}
        >
          Показать еще
        </Button>
      )}
    </section>
  );
};
Cases.displayName = "Cases";

export default Cases;
