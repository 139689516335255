"use client";

import React, { useEffect, useState } from "react";
import Button from "@/components/Button";
import {
  AnalyticsIcon,
  TaskCheckIcon,
  UnreadIcon,
  UpRightArrowIcon,
} from "@/components/Icon";
import Typography from "@/components/Typography";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import useEmblaCarousel from "embla-carousel-react";

import styles from "./styles.module.css";
import Image from "next/image";
import { useMediaQuery } from "usehooks-ts";

import taskImg1 from "../../../../public/task-card-1.png";
import taskImg2 from "../../../../public/task-card-2.png";
import taskImg3 from "../../../../public/task-card-3.png";
import analyticsImg1 from "../../../../public/analytics-workflow.png";
import avaibilityImg1 from "../../../../public/monitoring-service.png";
import avaibilityImg2 from "../../../../public/phone.png";

const steps = [
  {
    Icon: TaskCheckIcon,
    label: "Доска задач",
    description: (
      <Typography variant="p3" color="description">
        {
          "Модуль, где можно создавать доски для проектов, карточки задач в проекте и отслеживать этапы. Создавайте и перемещайте карточки на доске из колонки в колонку по мере выполнения задачи."
        }
      </Typography>
    ),
    content: (
      <>
        <Typography
          variant="p3"
          className={`${styles["card-description"]} ${styles["card-description-tasks"]}`}
        >
          Задачи <span>(3)</span>
        </Typography>
        <Image
          key={"task-1"}
          alt="Задача-1"
          src={taskImg1}
          placeholder="blur"
          fill
          className={`${styles["card-image"]} ${styles["card-image-task-1"]}`}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
        <Image
          key={"task-2"}
          alt="Задача-2"
          src={taskImg2}
          placeholder="blur"
          fill
          className={`${styles["card-image"]} ${styles["card-image-task-2"]}`}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
        <Image
          key={"task-3"}
          alt="Задача-3"
          src={taskImg3}
          placeholder="blur"
          fill
          className={`${styles["card-image"]} ${styles["card-image-task-3"]}`}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      </>
    ),
  },
  {
    Icon: AnalyticsIcon,
    label: "Аналитика",
    description: (
      <Typography variant="p3" color="description">
        {
          "Модуль аналитики, где вы можете отслеживать метрику о вашем ресурсе или рекламной кампании. Следите за прогрессом и просматривайте отчёт в любое удобное время."
        }
      </Typography>
    ),
    content: (
      <>
        <Typography variant="p3" className={`${styles["card-description"]}`}>
          Параметры пользователей, посетивших сайт
        </Typography>
        <Image
          key={"analytics"}
          alt="Аналитика"
          src={analyticsImg1}
          placeholder="blur"
          fill
          className={`${styles["card-image"]} ${styles["card-image-analytics"]}`}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
      </>
    ),
  },
  {
    Icon: UnreadIcon,
    label: "Доступность",
    description: (
      <>
        <Typography variant="p3" color="description">
          Мониторинг доступности сайта. Отслеживайте:
        </Typography>
        <ul className={styles.list}>
          <li>доступность сайта;</li>
          <li>сроков регистрации домена;</li>
          <li>сроков SSL-сертификатов;</li>
          <li>количества свободного места на сервере.</li>
        </ul>
      </>
    ),
    content: (
      <>
        <Image
          key={"avaibility"}
          alt="Доступность-Мониторинг"
          src={avaibilityImg1}
          placeholder="blur"
          fill
          className={`${styles["card-image"]} ${styles["card-image-availability-monitoring"]}`}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        <Image
          alt="Доступность-Поддержка"
          src={avaibilityImg2}
          placeholder="blur"
          fill
          className={`${styles["card-image"]} ${styles["card-image-availability-phone"]}`}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
      </>
    ),
  },
];

const Workflow: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const [currentStep, setCurrentStep] = useState(steps[0].label);
  const [activeTab, setActiveTab] = React.useState(1);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: false,
    active: false,
    loop: false,
    align: "start",
    breakpoints: { "(max-width: 767px)": { active: true } },
  });
  const isMobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    emblaApi?.scrollTo(steps.findIndex((s) => s.label === currentStep));
  }, [currentStep, emblaApi]);

  const onTabClick = (e: any, index: number) => {
    setActiveTab(index);
  };

  return (
    <section
      className={`${styles.container} ${className ? className : ""}`}
      {...rest}
    >
      <Typography variant="h5" color="accent">
        Workflow — экосистема true.code
      </Typography>

      <div className={styles.cta}>
        <Typography variant="h2" className={styles.headline}>
          Личный кабинет для&nbsp;каждого клиента
        </Typography>
        <div className={styles.descriprion}>
          <Typography variant="p1">
            Ставьте задачи, отслеживайте прогресс выполнения работ, проверяйте
            аналитику и метрики вашего веб-ресурса, отправляйте и получайте
            документы в одном пространстве.
          </Typography>
          {/* <Button
            variant="outline"
            RightElement={UpRightArrowIcon}
            className={styles.button}
          >
            {isMobile ? "Обсудить проект" : "Смотреть демо версию"}
          </Button> */}
        </div>
      </div>

      <section className={styles.catches}>
        <Accordion
          type="single"
          className={styles["accordion-container"]}
          defaultValue={steps[0].label}
          onValueChange={setCurrentStep}
        >
          {steps.map(({ label, Icon, description }, idx) => (
            <AccordionItem
              value={label}
              className={styles["accordion-item"]}
              data-active={currentStep === label}
              key={label}
            >
              <AccordionTrigger className={styles["accordion-trigger"]} asChild>
                <button className={styles["step-header"]}>
                  {currentStep === label && (
                    <Icon className={styles["step-icon"]} />
                  )}
                  {label}
                </button>
              </AccordionTrigger>
              <AccordionContent className={styles["accordion-content"]}>
                {description}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>

        <div className={styles["step-detail"]}>
          <div className={styles["step-detail-content"]}>
            {steps.find((s) => s.label === currentStep)?.content}
          </div>
        </div>

        <div defaultValue={steps[0].label} className={styles.tabs}>
          <div ref={emblaRef}>
            <div className={styles["tabs__list"]}>
              {steps.map(({ label, Icon, description }, idx) => (
                <div
                  key={label}
                  className={styles.tab__item}
                  data-active={currentStep === label}
                  onClick={() => setCurrentStep(label)}
                >
                  <Icon className={styles["step-icon"]} />
                  {label}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.tab__content}>
            <div className={styles["tab__content-desscription"]}>
              {steps.find((s) => s.label === currentStep)?.description}
            </div>

            <div className={styles["tab__content-card"]}>
              <div className={styles["tab__content-card-content"]}>
                {steps.find((s) => s.label === currentStep)?.content}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

Workflow.displayName = "Workflow";

export default Workflow;
