"use client";

import React from "react";
import Button from "@/components/Button";
import { UpRightArrowIcon } from "@/components/Icon";
import Typography from "@/components/Typography";

import styles from "./styles.module.css";
import Image from "next/image";
import { ContactDialog } from "@/blocks/ContactDialog";
import { useModal } from "@/hooks/useModal";

const Cta: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const { visible, show, close } = useModal({ defaultVisible: false });
  return (
    <>
      <ContactDialog open={visible} onClose={close} />
      <section
        className={`${styles.container} ${styles.card} ${
          className ? className : ""
        }`}
      >
        <Typography variant="h2" className={styles.headline}>
          Полное погружение в задачи вашего проекта
        </Typography>
        <Typography variant="p1" className={styles.subtitle}>
          Оставьте заявку, и мы свяжемся с вами в ближайшее время!
        </Typography>
        <Button
          variant="tertiary"
          RightElement={UpRightArrowIcon}
          className={styles.button}
          onClick={show}
        >
          Обсудить проект
        </Button>

        <Image
          className={styles.image}
          src="/deep-dive.svg"
          alt="DeepDive"
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
      </section>
    </>
  );
};
Cta.displayName = "Cta";

export default Cta;
