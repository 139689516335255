"use client";

import React from "react";
import Button from "@/components/Button";
import {
  LeftArrowIcon,
  RightArrowIcon,
  UpRightArrowIcon,
} from "@/components/Icon";
import Typography from "@/components/Typography";

import styles from "./styles.module.css";
import { useStep } from "@/hooks/useStep";
import IconButton from "@/components/IconButton";
import { ContactDialog } from "@/blocks/ContactDialog";
import { useModal } from "@/hooks/useModal";

const steps = [
  {
    label: "Погружение",
    description: "Погружение в задачу, определение ключевых целей",
  },
  {
    label: "Поиск решений",
    description: "Подбираем решения под потребности бизнеса",
  },
  {
    label: "План реализации",
    description: "Определение порядка реализации этапов проекта",
  },
  {
    label: "Разработка",
    description:
      "Реализация этапов в установленном порядке и тестирование продукта",
  },
  {
    label: "Техподдержка",
    description: (
      <span>
        Помощь в поддержке ваших
        <br />
        IT-систем,
      </span>
    ),
  },
];

const Main: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const [
    currentStep,
    { canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep },
  ] = useStep(steps.length);
  const { visible, show, close } = useModal({ defaultVisible: false });

  return (
    <>
      <ContactDialog open={visible} onClose={close} />

      <section className={`${styles.main} ${className ? className : ""}`}>
        <div>
          <Typography variant="h1" className={styles.headline}>
            Разработка, интеграция <br />и сопровождение ИТ-решений
          </Typography>
          <div className={styles.cta}>
            <Typography
              variant="p1"
              color="subtitle"
              className={styles.subtitle}
            >
              Разрабатываем ИТ-решения, которые отвечают требованиям всех
              стейкхолдеров и тенденций на рынке. Полностью погружаемся в каждый
              проект с этапа поступления заявки.
            </Typography>
            <Button
              variant="primary"
              RightElement={UpRightArrowIcon}
              className={styles.button}
              onClick={show}
            >
              Заполнить бриф
            </Button>
          </div>
        </div>

        <div className={styles.animation}>
          <div className={styles.aspect__ratio} />
          <div className={styles["progress-container"]}>
            <div
              className={styles["progress-bar"]}
              style={{
                width: `calc(${
                  (currentStep - 1) * (100 / steps.length) /// 1.3
                }% / var(--width-multiplier) + var(--width-offset))`,
              }}
            >
              <div className={styles["progress-bar-dummy"]} />
            </div>
            {steps.map(({ label }, idx) => (
              <React.Fragment key={idx}>
                <div
                  className={`${styles["progress-circle"]} ${
                    idx == steps.length - 1
                      ? styles["progress-circle-last"]
                      : ""
                  }`}
                  data-reached={idx == currentStep - 1}
                  style={{
                    width: `calc(${
                      idx * (100 / steps.length) /// 1.3
                    }% / var(--width-multiplier) +  var(--width-offset))`,
                    zIndex: 50 - idx,
                  }}
                  onMouseEnter={(e) => setStep(idx + 1)}
                >
                  <div className={styles["progress-bar-dummy"]} />
                </div>
                <div
                  onMouseEnter={() => setStep(idx + 1)}
                  className={`${styles["checkpoint"]} ${
                    idx === 0 ? styles["checkpoint-first"] : ""
                  }`}
                  data-reached={idx == currentStep - 1}
                  style={{
                    left: `calc(${
                      idx * (100 / steps.length)
                    }% / var(--width-multiplier))`,
                  }}
                />
                <span
                  onMouseEnter={() => setStep(idx + 1)}
                  className={`${styles["checkpoint-label"]}`}
                  data-reached={idx == currentStep - 1}
                  style={{
                    left: `calc(${
                      idx * (100 / steps.length)
                    }% / var(--width-multiplier))`,
                  }}
                >
                  {steps[idx].label}
                </span>
              </React.Fragment>
            ))}
          </div>

          <div className={styles.development}>
            <Typography variant="h4" className={styles["development-header"]}>
              {steps[currentStep - 1].label}
            </Typography>
            <Typography variant="p3" color="description">
              {steps[currentStep - 1].description}
            </Typography>
          </div>
          <div className={styles.navigation}>
            <IconButton
              Icon={LeftArrowIcon}
              className={styles["navigation-button"]}
              disabled={currentStep == 1}
              onClick={goToPrevStep}
            />
            <IconButton
              Icon={RightArrowIcon}
              className={styles["navigation-button"]}
              disabled={currentStep == steps.length}
              onClick={goToNextStep}
            />
          </div>
        </div>
      </section>
    </>
  );
};
Main.displayName = "Main";

export default Main;
