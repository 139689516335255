"use client";

import React from "react";
import Button from "@/components/Button";
import {
  LeftArrowIcon,
  RightArrowIcon,
  UpRightArrowIcon,
} from "@/components/Icon";
import Typography from "@/components/Typography";

import styles from "./styles.module.css";
import { useStep } from "@/hooks/useStep";
import { useMediaQuery } from "usehooks-ts";

const steps = [
  {
    label: "Договор",
    headline: "Подписание договора",
    description:
      "Заключение проектного соглашения, определение графиков и этапов платежей, установления сроков проекта и установление ответственности для всех участвующих сторон.",
  },
  {
    label: "ТЗ",
    headline: "Техническое задание",
    description:
      "Углубляемся в проект, составляем техническое описание, чтобы обеспечить взаимопонимание и согласованность между клиентом и нашей командой. Это также предполагает подтверждение объема работ.",
  },
  {
    label: "MVP",
    headline: "Определение MVP,  разработка",
    description:
      "На основе тщательной документации и глубокого погружения в проект мы совместно с клиентом определяем порядок реализации MVP, максимально согласовывая его с целями проекта.",
  },
  {
    label: "Запуск",
    headline: "Запуск и тестирование",
    description:
      "Разработанный функционал запускается для рыночного тестирования. Это позволяет проверить бизнес-гипотезы клиента и при необходимости внести коррективы в список невыполненных продуктов.",
  },
];

const About: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const [
    currentStep,
    { canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep },
  ] = useStep(steps.length);
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <section
      className={`${styles.container} ${className ? className : ""}`}
      {...rest}
    >
      <Typography variant="h5" color="accent">
        О компании
      </Typography>

      <div className={styles.cta}>
        <Typography variant="h2" className={styles.headline}>
          true.code — ваш партнёр в решении любых задач
        </Typography>
        <div className={styles.descriprion}>
          <Typography variant="p1">
            Полностью погружаемся в каждый проект с этапа поступления заявки.
            Привлекаем команду специалистов для&nbsp;поиска&nbsp;оптимального
            решения под каждую задачу.
          </Typography>
          {/* <Button
            variant="outline"
            RightElement={UpRightArrowIcon}
            className={styles.button}
          >
            {isMobile ? "Обсудить проект" : "Подробнее о компании"}
          </Button> */}
        </div>
      </div>

      <div className={styles.catches}>
        <section className={styles.experience}>
          <div className={styles.experience__item}>
            <span className={styles.experience__header}>8</span>
            <span className={styles.experience__subheader}>лет на рынке</span>
          </div>
          <div className={styles.experience__item}>
            <span className={styles.experience__header}>15+</span>
            <span className={styles.experience__subheader}>cпециалистов</span>
          </div>
          <div className={styles.experience__item}>
            <span className={styles.experience__header}>300+</span>
            <span className={styles.experience__subheader}>проектов</span>
          </div>
        </section>

        <section className={styles.steps}>
          <div className={styles["progress-container"]}>
            <div
              className={styles["progress-bar"]}
              style={{
                width: `calc(${
                  currentStep * (100 / steps.length)
                }% * var(--width-multiplier))`,
              }}
            />
            {steps.map(({ label }, idx) => (
              <React.Fragment key={idx}>
                <div
                  className={`${styles["checkpoint"]}`}
                  data-reached={idx <= currentStep - 1}
                  style={{
                    left: `calc(${
                      idx * (100 / steps.length)
                    }% * var(--width-multiplier))`,
                  }}
                />
                <span
                  className={`${styles["checkpoint-label"]}`}
                  style={{
                    left: `calc(${
                      idx * (100 / steps.length)
                    }% * var(--width-multiplier))`,
                  }}
                >
                  {steps[idx].label}
                </span>
              </React.Fragment>
            ))}
          </div>

          <div className={styles["step-text"]}>
            <Typography variant="h4">
              {`${currentStep}. ${steps[currentStep - 1].headline}`}{" "}
            </Typography>
            <Typography variant="p2">
              {`${steps[currentStep - 1].description}`}
            </Typography>
          </div>

          <div className={styles.buttons}>
            {canGoToPrevStep && (
              <Button
                variant="outline"
                className={styles["button-prev"]}
                onClick={goToPrevStep}
              >
                <LeftArrowIcon /> Назад
              </Button>
            )}
            {canGoToNextStep && (
              <Button
                variant="outline"
                className={styles["button-next"]}
                onClick={goToNextStep}
              >
                Далее <RightArrowIcon />
              </Button>
            )}
          </div>
        </section>
      </div>
    </section>
  );
};
About.displayName = "About";

export default About;
