"use client";

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseIcon, UploadIcon } from "@/components/Icon";
import IconButton from "@/components/IconButton";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { boolean, z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormProvider,
} from "@/components/Form";
import Typography from "@/components/Typography";
import styles from "./styles.module.css";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import { useModal } from "@/hooks/useModal";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const SuccessDialog: React.FC<Props> = ({
  open,
  onClose = () => {},
}) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.DialogOverlay} />
        <Dialog.Content
          className={`${styles.DialogContent} ${styles.DialogContentSuccess}`}
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          onInteractOutside={onClose}
        >
          <div className={styles.DialogSuccessContentContainer}>
            <Dialog.Title asChild>
              <Typography variant="h2" className={styles.DialogTitle}>
                Успешно!
              </Typography>
            </Dialog.Title>
            <Dialog.Description asChild>
              <Typography
                variant="p1"
                className={`${styles.DialogDescription} ${styles.DialogSuccessDescription}`}
              >
                <>
                  Спасибо за вашу заявку.
                  <br />
                  Мы свяжемся с вами в ближайшее время!
                </>
              </Typography>
            </Dialog.Description>
            <Button
              variant="primary"
              className={`${styles.SubmitBtn} ${styles.SubmitBtnSuccess}`}
              onClick={onClose}
            >
              Закрыть
            </Button>
          </div>
          <Dialog.Close asChild>
            <IconButton
              onClick={onClose}
              className={styles.CloseBtn}
              aria-label="Close"
              Icon={CloseIcon}
              variant="circle"
            ></IconButton>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
