"use client";

import React from "react";
import Button from "@/components/Button";
import { EyeIcon, UpRightArrowIcon } from "@/components/Icon";
import Typography from "@/components/Typography";

import styles from "./styles.module.css";
import Image from "next/image";
import NextLink from "next/link";

import useEmblaCarousel from "embla-carousel-react";

import blogImg1 from '../../../../public/images/blog/6-14-1.png'
import blogImg2 from '../../../../public/images/blog/2-1.png'
import blogImg3 from '../../../../public/images/blog/predproekt-11.png'

const casesBlog = [
  {
    img: blogImg1,
    title:
      "Сколько экономит компания, переводя бумажные отчеты в бизнес-процесс? Кейс true.code для компании из Новой Зеландии.",
    description:
      "Мы разработали и внедрили сервис двусторонней интеграции для учета рабочего времени с аналитикой затрат по каждому проекту и расчету заработной платы по каждому дню",
    date: "09.08.2024",
    views: "700",
    href: "/skolko-jekonomit-kompanija-perevodja-bumazhnye-otchety-v-biznes-process-kejs-true-code-dlja-kompanii-iz-novoj-zelandii",
  },
  {
    img: blogImg2,
    title:
      "Профессиональные навыки или хорошая коммуникация? Что работодатели сейчас выбирают в ИТ",
    description:
      "После 9 лет лет руководства компанией, я на 180 градусов поменял свое мировоззрение по поводу найма. ",
    date: "18.07.2024",
    href: "/professionalnye-navyki-ili-horoshaja-kommunikacija-chto-rabotodateli-sejchas-vybirajut-v-it",
    views: "700",
  },
  {
    img: blogImg3,
    title: "Предпроектное исследование — успех на старте",
    description:
      "В сфере ИТ-разработки среди заказчиков существует распространенное мнение, что основная задача разработчиков заключается в программировании, создании кода, и в предпроектном исследовании нет необходимости.",
    date: "30.11.2023",
    href: "/predproektnoe-issledovanie-uspeh-na-starte",
    views: "700",
  },
];
const Blog: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  return (
    <section className={`${styles.container} ${className ? className : ""}`}>
      <Typography variant="h5" color="accent">
        Блог
      </Typography>

      <div className={styles.cta}>
        <Typography variant="h2" className={styles.headline}>
          Экспертное мнение и&nbsp;прошедшие события
        </Typography>
        <Button
          variant="outline"
          RightElement={UpRightArrowIcon}
          className={styles.button}
          asChild
        >
          <NextLink href="/articles">Смотреть все новости</NextLink>
        </Button>
      </div>

      <section className={styles.cases}>
          {casesBlog.map(
            ({ date, description, img, title, views, href }, idx) => (
              <NextLink
                className={styles.card}
                key={idx}
                data-idx={idx}
                href={`/articles${href}`}
              >
                <Image
                  className={styles.card__image}
                  src={img}
                  alt=""
                  placeholder="blur"
                  fill
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                />
                <div className={styles["card-content"]}>
                  <Typography variant="h6" className={styles.card__headline}>
                    {title}
                  </Typography>
                  <Typography
                    variant="p3"
                    color="description"
                    className={styles.card__description}
                  >
                    {description}
                  </Typography>
                </div>

                <div className={styles.card__footer}>
                  <Typography variant="p2">{date}</Typography>
                  {/* <EyeIcon className={styles["eye-icon"]} />
                <Typography variant="p2">{views} показов</Typography> */}
                </div>
              </NextLink>
            )
          )}
      </section>
    </section>
  );
};
Blog.displayName = "Blog";

export default Blog;
