import { useState, useEffect } from "react";

function getWindowDimensions() {
  if (typeof window === "undefined") {
    return {
      width: 1024,
      height: 1024,
    };
  }

  const { outerWidth: width, outerHeight: height } = window;

  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      if (typeof window === "undefined") return undefined;
      return window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [windowDimensions.width, windowDimensions.height];
}
