"use client";

import React, { useCallback } from "react";
import Button from "@/components/Button";
import Typography from "@/components/Typography";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { z } from "zod";
import validator from "validator";

import styles from "./styles.module.css";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormProvider,
} from "@/components/Form";
import { FormMessage } from "@radix-ui/react-form";
import Checkbox from "@/components/Checkbox";
import { SuccessDialog } from "@/blocks/ContactDialog/SuccessDialog";
import { useModal } from "@/hooks/useModal";
import submitForm from "@/utils/submitFormMail";

const formSchema = z.object({
  name: z.string().min(0, {
    message: "Необходимо заполнить поле",
  }),
  phone: z
    .string()
    .min(1, {
      message: "Необходимо заполнить поле",
    })
    .refine(
      (val) =>
        validator.isMobilePhone(
          val
            .slice(1)
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(" ", "")
            .replaceAll("-", ""),
          "ru-RU"
        ),
      {
        message: "Поле Введено некоректно",
      }
    ),
  description: z.string().min(1, {
    message: "Необходимо заполнить поле",
  }),
  terms: z.boolean().refine((val) => val === true, {
    message: "Необходимо согласиться",
  }),
});

const Contact: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      phone: "",
      description: "",
      terms: false,
    },
  });
  const registerWithMask = useHookFormMask(form.register);
  const { close, show, visible } = useModal();

  const onSubmit = useCallback(
    (values: z.infer<typeof formSchema>) => {
      const formData = new FormData();
      formData.set("name", values.name);
      formData.set("phone", values.phone);
      formData.set("description", values.description);
      submitForm(formData);
      show();
    },
    [show]
  );
  
  return (
    <>
      <SuccessDialog open={visible} onClose={close} />
      <section
        className={`${styles.container} ${styles.card} ${
          className ? className : ""
        }`}
      >
        <div className={styles.headline}>
          <Typography variant="h2">
            Цифровая трансформация начинается здесь
          </Typography>
          <Typography variant="p1" className={styles.subtitle}>
            Хотите обсудить проект или предложить идею? Заполните форму, и мы
            свяжемся с вами в&nbsp;ближайшее&nbsp;время
          </Typography>
        </div>

        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.form__contact}>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className={styles.form__item}>
                    <FormLabel className={styles.label} htmlFor="name">
                      Имя
                    </FormLabel>
                    <FormControl>
                      <input
                        {...field}
                        placeholder="Имя"
                        name="name"
                        id="name"
                        className={styles.input}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className={styles.form__item}>
                    <FormLabel className={styles.label} htmlFor="phone">
                      Телефон
                    </FormLabel>
                    <FormControl>
                      <>
                        <input
                          {...registerWithMask("phone", ["+7 (999) 999-99-99"])}
                          placeholder="Телефон *"
                          name="phone"
                          id="phone"
                          type="tel"
                          className={`${styles.input} ${
                            form.formState.errors.phone?.message
                              ? styles.error
                              : ""
                          }`}
                        />
                        <span className={styles.errorMessage}>
                          {form.formState.errors.phone?.message}
                        </span>
                      </>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className={styles.form__item}>
                  <FormLabel className={styles.label} htmlFor="description">
                    Опишите свой проект
                  </FormLabel>
                  <FormControl>
                    <>
                      <textarea
                        {...field}
                        placeholder="Опишите свой проект *"
                        name="description"
                        id="description"
                        className={`${styles.textarea} ${
                          form.formState.errors.description?.message
                            ? styles.error
                            : ""
                        }`}
                      />
                      <span className={styles.errorMessage}>
                        {form.formState.errors.description?.message}
                      </span>
                    </>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="terms"
              render={({ field }) => (
                <FormItem className={styles.form__item}>
                  <FormControl>
                    <label
                      htmlFor="terms"
                      className={`${styles.terms} ${
                        form.formState.errors.terms?.message ? styles.error : ""
                      }`}
                    >
                      <Checkbox
                        id="terms"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      Я согласен на обработку персональных данных
                    </label>
                  </FormControl>
                </FormItem>
              )}
            />
            <Button variant="tertiary" className={styles.button} type="submit">
              Отправить
            </Button>
          </Form>
        </FormProvider>
      </section>
    </>
  );
};
Contact.displayName = "Contact";

export default Contact;
